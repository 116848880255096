html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-size: 14px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
button,
a,
span,
div,
i {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none;
  color: inherit;
}
button {
  position: relative;
  box-sizing: border-box;
  border: none;
  overflow: hidden;
}
input,
select {
  outline: none;
}
p {
  text-align: justify;
}
.ads_afs_container {
  border-top: 1px solid rgba(224,226,236,0.5);
  display: block;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 2em;
}
.ads_afs_container #afscontainer1,
.ads_afs_container #afscontainer2 {
  width: 50% !important;
  display: inline-block;
  vertical-align: top;
  padding: 1em 1em 0 1em;
  box-sizing: border-box;
}
.ads_afs_container #afscontainer1 iframe,
.ads_afs_container #afscontainer2 iframe {
  box-sizing: border-box;
  display: block;
}
@media (max-width: 480px) {
  .ads_afs_container #afscontainer1,
  .ads_afs_container #afscontainer2 {
    width: 100% !important;
  }
}
.geosuggest {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: none;
  transition: all ease 0.3s;
}
.geosuggest__input:placeholder {
  color: #4a4a4a;
}
.geosuggest__input:focus {
  box-shadow: 0 0 0 transparent;
  outline: none;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid rgba(204,223,244,0.5);
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: all ease 0.3s;
}
.geosuggest__suggests--hidden {
  border: none;
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: rgba(245,245,245,0.7);
}
.geosuggest__item:last-child {
  background-image: url("/img/map/powered-by-google.png");
  background-repeat: no-repeat;
  background-position: 98% 93%;
}
.geosuggest__item--active {
  background: rgba(204,223,244,0.3);
  color: #4a4a4a;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: rgba(204,223,244,0.3);
}
.CalendarDay {
  text-align: center;
  vertical-align: middle;
  padding: 0;
  box-sizing: border-box;
  color: #565a5c;
  cursor: pointer;
  width: 39px;
  height: 36px;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.CalendarDay__button {
  font-size: 14px;
  letter-spacing: -0.25px;
  cursor: pointer;
  background: #fff;
  padding: 0;
}
.CalendarDay__button:focus {
  outline: none;
}
.CalendarDay--highlighted-calendar:after {
  left: 45%;
  position: absolute;
  background-color: #17d280;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 30px;
  top: 80%;
}
.CalendarDay--outside {
  border: 0;
  cursor: default;
}
.CalendarDay--outside:active {
  background: #fff;
}
.CalendarDay--hovered {
  background: #e4e7e7;
  color: inherit;
  border-radius: 30px;
}
.CalendarDay--hovered .CalendarDay__button {
  background: #e4e7e7;
}
.CalendarDay--blocked-minimum-nights {
  color: #cacccd;
  background: #fff;
  cursor: default;
}
.CalendarDay--blocked-minimum-nights:active {
  background: #fff;
}
.CalendarDay--selected-span {
  background: #ccdff4;
  color: #4a4a4a;
  border-radius: 0;
}
.CalendarDay--selected-span .CalendarDay__button {
  background: #ccdff4;
}
.CalendarDay--selected-span:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.CalendarDay--selected-span:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: #005fc9;
}
.CalendarDay--selected-span .CalendarDay--hovered,
.CalendarDay--selected-span:active {
  color: #fff;
}
.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #ccdff4;
  color: #4a4a4a;
  border-radius: 0;
}
.CalendarDay--hovered-span .CalendarDay__button,
.CalendarDay--after-hovered-start .CalendarDay__button {
  background: #ccdff4;
}
.CalendarDay--hovered-span:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.CalendarDay--hovered-span:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarDay--blocked-calendar {
  background: #cacccd;
  color: #82888a;
  cursor: default;
}
.CalendarDay--blocked-calendar:active {
  background: #cacccd;
}
.CalendarDay--blocked-out-of-range {
  color: #cacccd;
  background: #fff;
  cursor: default;
}
.CalendarDay--blocked-out-of-range:active {
  background: #fff;
}
.CalendarDay--blocked-out-of-range:after {
  opacity: 0.5;
}
.CalendarDay--selected,
.CalendarDay--selected-start,
.CalendarDay--selected-end {
  background: #ccdff4;
  color: #fff;
  z-index: 4;
  font-weight: 700;
}
.CalendarDay--selected:before,
.CalendarDay--selected-start:before,
.CalendarDay--selected-end:before {
  will-change: transform;
  position: absolute;
  display: block;
  content: '';
  width: 38px;
  height: 36px;
  animation: grow-date 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
  border-radius: 50%;
  background: #005fc9;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.CalendarDay--selected .CalendarDay__button,
.CalendarDay--selected-start .CalendarDay__button,
.CalendarDay--selected-end .CalendarDay__button {
  background: #005fc9;
}
.CalendarDay--selected-start {
  border-radius: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.CalendarDay--selected-end {
  border-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.CalendarDay--selected-end:first-child:after {
  margin-left: -11px;
  width: 22px;
}
.CalendarDay--hovered-span.CalendarDay--hovered {
  background: #ccdff4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarMonth {
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  user-select: none;
}
.CalendarMonth:first-of-type {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonth table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth--horizontal {
  display: inline-block;
  min-height: 100%;
}
.CalendarMonth--vertical {
  display: block;
}
.CalendarMonth__caption {
  background: #fff;
  font-weight: 700;
  color: #4a4a4a;
  margin-top: 7px;
  font-size: 15px;
  letter-spacing: -0.25px;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}
.CalendarMonth--horizontal .CalendarMonth__caption,
.CalendarMonth--vertical .CalendarMonth__caption {
  padding: 15px 0 35px;
  font-size: 16px;
  letter-spacing: -0.25px;
  width: 273px;
  min-width: unset;
}
.CalendarMonth--vertical-scrollable .CalendarMonth__caption {
  padding: 5px 0;
}
.CalendarMonthGrid {
  background: #fff;
  z-index: 0;
  text-align: left;
}
.CalendarMonthGrid--animating {
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}
.CalendarMonthGrid--horizontal {
  position: absolute;
  left: 9px;
  width: 1200px;
}
.CalendarMonthGrid--vertical {
  width: 300px;
  margin: 0 auto;
}
.CalendarMonthGrid--vertical-scrollable {
  width: 300px;
  margin: 0 auto;
  overflow-y: scroll;
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}
.DayPicker--horizontal {
  background: #fff;
  box-shadow: 0 10px 6px rgba(0,0,0,0.05), 0 0 0 1px #ccdff4;
  border-radius: 3px;
}
.DayPicker.DayPicker--portal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker__week-headers {
  margin-left: 9px;
}
.DayPicker--vertical.DayPicker--portal {
  position: initial;
}
.DayPicker--vertical .DayPicker__week-header {
  margin-left: -150px;
  left: 50%;
}
.DayPicker__week-headers {
  position: relative;
}
.DayPicker__week-header {
  font-size: 13px;
  color: #4a4a4a;
  position: absolute;
  width: 300px;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
  font-weight: 500;
}
.DayPicker__week-header ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
}
.DayPicker__week-header li {
  display: inline-block;
  width: 39px;
  text-align: center;
}
.DayPicker--vertical-scrollable {
  height: 100%;
}
.DayPicker--vertical-scrollable .DayPicker__week-header {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker--vertical-scrollable .transition-container--vertical {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.transition-container {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.transition-container--horizontal {
  transition: height 0.2s ease-in-out;
  height: 400px !important;
}
.transition-container--horizontal:before {
  position: absolute;
  background-color: #ccdff4;
  content: '';
  width: 90%;
  height: 1px;
  bottom: 75px;
  margin-left: 5%;
}
.transition-container--vertical {
  width: 100%;
}
.DayPickerNavigation__prev,
.DayPickerNavigation__next {
  cursor: pointer;
  line-height: 0.78;
  user-select: none;
}
.DayPickerNavigation__prev--default,
.DayPickerNavigation__next--default {
  background-color: #fff;
  color: #757575;
}
.DayPickerNavigation__prev--default:active,
.DayPickerNavigation__next--default:active {
  background: #f2f2f2;
}
.DayPickerNavigation--horizontal {
  position: relative;
}
.DayPickerNavigation__prev {
  left: 22px;
}
.DayPickerNavigation__next {
  right: 22px;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev,
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  border-radius: 3px;
  padding: 6px 9px;
  top: 13px;
  z-index: 2;
  position: absolute;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
  height: 19px;
  width: 19px;
  fill: #005fc9;
}
.DayPickerNavigation--vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
  z-index: 2;
}
.DayPickerNavigation__next--default {
  border-left: 0;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev,
.DayPickerNavigation--vertical .DayPickerNavigation__next {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  text-align: center;
  font-size: 2.5em;
  padding: 5px;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPickerNavigation--vertical-scrollable {
  position: relative;
}
.DayPickerNavigation--vertical-scrollable .DayPickerNavigation__next {
  width: 100%;
}
.DateInput {
  width: auto;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 22px;
  color: rgba(0,0,0,0.4);
  margin: 0;
  padding: 8px 0px;
  background: #fff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.DateInput--with-caret:before,
.DateInput--with-caret:after {
  top: 66px;
  border-left: 1px solid #ccdff4;
  border-top: 1px solid #ccdff4;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotateZ(45deg);
  left: 22px;
  z-index: 9999;
}
.DateInput--disabled {
  background: #cacccd;
}
.DateInput__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
}
.DateInput__input[readonly] {
  user-select: none;
}
.DateInput__display-text {
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px;
}
.DateInput__display-text--has-input {
  color: #484848;
  font-weight: 500;
}
.DateInput__display-text--focused:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #1c1f56;
  position: absolute;
  bottom: -4px;
  animation-fill-mode: forwards;
  transform: translateX(1px);
  max-width: 90%;
  width: 90%;
}
.DateInput__display-text--disabled {
  font-style: italic;
}
.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateRangePicker {
  padding: 0 5px 0 20px;
  position: relative;
  display: inline-block;
}
.DateRangePicker__picker {
  z-index: 9998;
  background-color: #fff;
  position: absolute;
  top: 72px;
}
.DateRangePicker__picker :focus {
  outline: none;
}
.DateRangePicker__picker--direction-left {
  left: -220px !important;
}
.DateRangePicker__picker--direction-right {
  right: 0;
}
.DateRangePicker__picker--full-screen-portal {
  transform: translateZ(1px);
}
.DateRangePicker__picker__horizontal {
  opacity: 1;
}
.DateRangePicker--portal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker--full-screen-portal {
  background-color: #fff;
  transform: translateZ(1px);
}
.DateRangePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.DateRangePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DateRangePicker__close:hover,
.DateRangePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.DateRangePickerInput {
  display: inline-block;
  width: 272px;
}
.DateRangePickerInput--disabled {
  background: #cacccd;
}
.DateRangePickerInput__arrow {
  display: inline-block;
  vertical-align: middle;
  padding: 0px 5px;
}
.DateRangePickerInput__arrow svg {
  padding: 0px 3px;
  vertical-align: middle;
  fill: #005fc9;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput__clear-dates {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.DateRangePickerInput__clear-dates svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput__clear-dates--hide {
  visibility: hidden;
}
.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput__calendar-icon {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput__calendar-icon svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 72px;
}
.SingleDatePicker__picker--direction-left {
  left: 0;
}
.SingleDatePicker__picker--direction-right {
  right: 0;
}
.SingleDatePicker__picker--portal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker__picker--full-screen-portal {
  background-color: #fff;
  transform: translateZ(1px);
}
.SingleDatePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.SingleDatePicker__close:hover,
.SingleDatePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.SingleDatePickerInput {
  background-color: #fff;
  border: 1px solid #dbdbdb;
}
.SingleDatePickerInput__clear-date {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.SingleDatePickerInput__clear-date svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput__clear-date--hide {
  visibility: hidden;
}
.SingleDatePickerInput__clear-date:focus,
.SingleDatePickerInput__clear-date--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
td:last-child tr:last-child {
  border-radius: 50%;
}
.transition-container--vertical {
  margin-top: 12%;
}
.DateRangePicker__picker--full-screen-portal {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0 !important;
  position: fixed;
}
.DateRangePicker__picker--full-screen-portal .DayPicker--horizontal {
  top: 40vh;
}
.-testA-placeHolder .DateInput {
  color: #4a4a4a;
}
.-testA-placeHolder .DateInput__display-text--focused {
  background: #ccdff4;
  border-color: #ccdff4;
  border-radius: 3px;
}
.-testA-placeHolder .DateInput__display-text--focused:after {
  display: none;
}
.DateInput--with-caret:before,
.DateInput--with-caret:after {
  opacity: 1;
  transition: opacity 0.1s;
  transition-delay: 0.2s;
}
@media (min-width: 768px) {
  .-dissapear-caret:before,
  .-dissapear-caret:after {
    opacity: 0;
  }
  .-dissapear-cal {
    transition: opacity 0.1s;
    transition-delay: 0.2s;
    opacity: 0;
  }
@-moz-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@-webkit-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@-o-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
}
.DayPickerKeyboardShortcuts__show.DayPickerKeyboardShortcuts__show--bottom-right {
  display: none;
}
.compatibility-modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  text-align: center;
  padding: 30px 10px 0 10px;
  transition: 1s ease all;
}
.compatibility-modal__title {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #4a4a4a;
}
.compatibility-modal__text {
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 10px;
}
.compatibility-modal__browsers {
  width: 100%;
  text-align: center;
  padding-top: 30px;
}
.compatibility-modal__browsers__item {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  padding: 10px 5px 10px 5px;
  width: 40%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
}
.compatibility-modal__browsers__item__image {
  width: 70%;
}
.compatibility-modal__browsers__item__text {
  text-transform: capitalize;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}
.hundredmodal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 9996;
}
.hundredmodal.menu_filters-container-modal {
  text-align: left;
}
.hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.menu_user-container-modal {
  text-align: right;
}
.hundredmodal.menu_user-container-modal .hundredmodal__content {
  width: auto;
}
.hundredmodal.menu_user-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInRight;
}
.hundredmodal.menu_user-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutRight;
}
.hundredmodal.menu_user-container-modal.-animation-leave .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.establishment-container-modal.-animation-leave .hundredmodal__veil {
  display: none;
}
.hundredmodal.login-container-modal {
  transform: translateZ(1px);
}
.hundredmodal.share-container-modal .hundredmodal__content__close,
.hundredmodal.share-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-share-container-modal .hundredmodal__content__close,
.hundredmodal.establishment-share-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.alert-popup-focus-lost-container-modal {
  transform: translateZ(1px);
  text-align: center;
}
.hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__content__close,
.hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__veil {
  display: flex;
}
.hundredmodal.leads-form-container-modal {
  text-align: center;
}
.hundredmodal.search-mobile-container-modal {
  transform: translateZ(1px);
}
.hundredmodal.search-mobile-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.search-mobile-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.notification-container-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  top: initial;
  right: initial;
  height: auto;
  padding: 0;
  width: 100%;
  overflow: initial;
}
.hundredmodal.notification-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.notification-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal__content {
  position: relative;
  margin: auto;
  width: auto;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  display: inline-block;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
}
.hundredmodal__content__close {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.25px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  right: 0;
  left: 100%;
  transform: translate(9px, -30px);
  z-index: 9997;
  top: 0;
}
.hundredmodal__veil {
  background-color: rgba(0,0,0,0.6);
  height: 200vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 200vw;
}
.hundredmodal.language-suggest-container-modal .hundredicons-cross:before {
  display: none;
}
@media (max-width: 767px) {
  .hundredmodal {
    display: block;
  }
  .hundredmodal__content {
    height: 100vh;
    width: 100%;
  }
  .hundredmodal__content__close {
    color: #4a4a4a;
    transform: translate(-10px, 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: auto;
    font-size: 16px;
    letter-spacing: -0.25px;
    border-radius: 50%;
    background-color: #fff;
    padding-left: 8vw;
    top: 0;
  }
}
@media (min-width: 768px) {
  .hundredmodal.menu_filters-container-modal {
    text-align: left;
    transform: none;
  }
  .hundredmodal.menu_filters-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__content {
    animation-name: slideInLeft;
  }
  .hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__content {
    animation-name: slideOutLeft;
  }
  .hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__veil {
    display: none;
  }
  .hundredmodal.menu_user-container-modal {
    text-align: right;
  }
  .hundredmodal.establishment-container-modal {
    text-align: center;
    padding: 40px 0;
  }
  .hundredmodal.establishment-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__content {
    animation-name: slideInUp;
  }
  .hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.share-container-modal {
    text-align: center;
    padding: 22vh 0;
  }
  .hundredmodal.share-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.share-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.login-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.login-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.establishment-share-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.establishment-share-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.establishment-share-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal {
    text-align: center;
    padding: 12vh 0 2vh;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal.-animation-leave .hundredmodal__content {
    display: none;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal.-animation-leave .hundredmodal__veil {
    display: none;
  }
  .hundredmodal.date-picker-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.date-picker-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.language-suggest-container-modal {
    text-align: center;
    padding: 30vh 0;
  }
  .hundredmodal.language-suggest-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.language-suggest-container-modal .hundredicons-cross:before {
    display: none;
  }
  .hundredmodal.leads-form-container-modal {
    text-align: center;
    padding: 40px 0;
  }
  .hundredmodal.leads-form-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.leads-form-container-modal .hundredmodal__veil {
    display: inherit;
  }
}
.hundredmodal#user_social_layer {
  z-index: 9999;
}
.hundredmodal#menus_layer {
  z-index: 9997;
}
.hundredmodal#especial_layer {
  z-index: 9996;
}
.shape__modal {
  z-index: 9999;
}
.shape__menu {
  z-index: 9997;
}
.map-notification.notification {
  position: fixed;
  right: 10px;
  top: calc(100vh - 80vh);
  width: auto;
  min-height: auto;
  max-width: 220px;
  border-radius: 0.5em;
  animation-name: slideInRight;
  animation-duration: 500ms;
  animation-delay: 0ms;
}
@media (max-width: 767px) {
  .map-notification.notification {
    top: 20vh;
  }
}
.map-notification.notification .notification__content__title {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  white-space: normal;
  text-align: right;
}
.-animation-leave .map-notification.notification,
.-animation-enter .map-notification.notification {
  animation-name: none;
  animation-delay: 10s;
}
.search-modal {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: fixed;
  overflow: scroll;
  z-index: 9996;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  animation: slideInUp;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.78, 0.6, 0.4, 0.99);
  transition: all ease 0.5s;
  display: block;
}
.search-modal__header {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.search-modal__header__icon {
  font-size: 18px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.search-modal__header__text {
  position: relative;
  margin-right: 3%;
}
.search-modal__header__text:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 30px;
  left: -15px;
  top: 9px;
  background-color: #17d280;
}
.search-modal__body {
  display: block;
  padding: 50px 0 150px;
}
.search-modal__body .search-bar-molecule {
  background-color: #fff;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}
.search-modal__body .search-bar-molecule__desktop__box {
  box-shadow: none;
  border: none;
  border-top: 1px solid #ccdff4;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes {
  padding-left: 12px;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes__title {
  color: #4a4a4a;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label:after {
  box-shadow: inset 0 0 0 1px #ccdff4;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
  color: #9e9e9e;
}
.search-modal.-closed {
  transform: translateY(110%);
  top: 110%;
}
.search-modal.-opened {
  transform: translateY(0);
  top: 0;
}
@media (min-width: 500px) {
  .search-modal__body .search-bar-molecule {
    max-width: 500px;
    margin: 0 auto;
  }
  .search-modal__body .search-bar-molecule__desktop__box {
    border: 1px solid #ccdff4;
  }
}
@media (min-width: 414px) {
  .search-modal__body {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  .search-modal__body .search-bar-molecule {
    margin: 0 auto;
    max-width: inherit;
  }
}
.veil {
  position: fixed;
  transition: all ease 0.5s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.veil.-show {
  z-index: 9995;
  background: rgba(0,0,0,0.4);
}
.veil.-hide {
  z-index: 0;
  background: transparent;
  display: none;
}
.steps {
  background-color: #dae8f7;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding-top: 280px;
  margin-top: -260px;
  padding-bottom: 20px;
}
.steps .steps__title,
.steps .steps__subtitle {
  display: none;
}
.steps .steps__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  flex-direction: column;
}
.steps .steps__list__line {
  display: none;
}
.steps .steps__list__item {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 90px;
  flex-direction: row;
  max-width: none;
  width: 100%;
  margin: 15px 0;
}
.steps .steps__list__item__image {
  background-color: #fff;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 46px;
  margin-bottom: 7px;
  overflow: hidden;
  text-align: center;
  width: 46px;
}
.steps .steps__list__item__image__first,
.steps .steps__list__item__image__second,
.steps .steps__list__item__image__third {
  background-image: url("https://static.100r.systems/img/pages/home/steps/steps-sprite-B.svg");
  background-repeat: no-repeat;
  background-size: 120px;
  display: block;
  height: 100%;
  width: 100%;
}
.steps .steps__list__item__image__first {
  background-position: 5px 8px;
}
.steps .steps__list__item__image__second {
  background-position: -35px 8px;
}
.steps .steps__list__item__image__third {
  background-position: -78px 8px;
}
.steps .steps__list__item__text {
  color: #4a4a4a;
  display: block;
  flex-shrink: 1;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  max-width: unset;
  text-align: left;
  margin-left: 15px;
  height: auto;
}
.steps .steps__list__item__description {
  display: none;
}
.steps.home-version-new {
  padding-top: 280px;
  margin-top: -260px;
  min-height: 505px;
}
.steps.home-version-new .steps__list {
  flex-direction: column;
}
.steps.home-version-new .steps__list__item {
  width: 100%;
  flex-direction: row;
  margin: 15px 0;
}
.steps.home-version-new .steps__list__item__text {
  max-width: inherit;
  text-align: left;
  margin-left: 15px;
  height: auto;
}
.steps.home-version-new .steps__list__item__text .step-link {
  font-weight: 700;
}
.steps.home-version-new .steps__list__item__text .step-link:hover {
  color: #005fc9;
}
.steps.home-version-new .steps__list__item__image {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .steps {
    min-height: inherit;
    margin-top: -90px;
    padding-top: 200px;
  }
  .steps .steps__list__item {
    max-width: 300px;
    width: 33%;
    padding: 0 16px;
  }
  .steps .steps__list__item__image {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
  .steps .steps__list__item__image__first,
  .steps .steps__list__item__image__second,
  .steps .steps__list__item__image__third {
    background-size: 200px;
  }
  .steps .steps__list__item__image__first {
    background-position: 4px 9px;
  }
  .steps .steps__list__item__image__second {
    background-position: -63px 9px;
  }
  .steps .steps__list__item__image__third {
    background-position: -133px 9px;
  }
  .steps .steps__list__item__text {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 24px;
    width: 100%;
    max-width: inherit;
    padding-bottom: 10px;
    height: auto;
  }
  .steps .steps__list__item__description {
    color: #4a4a4a;
    display: block;
    font-size: 16px;
    letter-spacing: -0.25px;
    height: 48px;
    line-height: 24px;
    max-width: 280px;
    text-align: center;
    width: 100%;
  }
  .steps.home-version-new {
    margin-top: -230px;
    display: block;
    background-color: #fff;
  }
  .steps.home-version-new .steps__list {
    flex-direction: row;
    max-width: inherit;
    margin: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .steps.home-version-new .steps__list__item {
    flex-direction: column;
    padding: 0 22px;
    width: 33%;
  }
  .steps.home-version-new .steps__list__item__text {
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.25px;
    margin: 0;
  }
  .steps.home-version-new .steps__list__item__image {
    margin-bottom: 25px;
    border: 1px solid #ccdff4;
  }
}
.claim {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: auto;
  min-height: 150px;
  padding: 0 7.03125%;
  margin: auto;
  user-select: none;
  cursor: default;
  margin: 15px 0;
  margin-bottom: 24px;
}
.claim .claim__subtitle {
  color: #353a8e;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 30px;
  order: 1;
  padding-top: 10px;
  text-align: left;
}
.claim .claim__title {
  color: #4a4a4a;
  font-size: 25px;
  font-weight: normal;
  height: auto;
  line-height: 30px;
  order: 2;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .claim {
    height: 40vh;
    margin: auto;
    max-width: 980px;
    min-height: 250px;
    justify-content: center;
    width: 100%;
  }
  .claim .claim__title {
    color: #005fc9;
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
    max-width: 700px;
    line-height: 40px;
  }
  .claim .claim__subtitle {
    font-size: 32px;
    padding-top: 0;
    line-height: 52px;
    font-weight: 700;
  }
}
.dropdown {
  user-select: none;
  padding: 0 15px;
  list-style: none;
}
.dropdown__title {
  cursor: pointer;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  text-align: center;
}
.dropdown__list {
  border-radius: 3px;
  width: 190px;
  padding-top: 10px;
  display: none;
  position: absolute;
  transform: translateX(-20px) translateZ(0);
  transition: all ease 0.3s;
  background: #fff;
  z-index: 9995;
  list-style-type: none;
}
.dropdown__list:hover {
  display: block;
}
.dropdown__list:before {
  border-left: 1px solid #ccdff4;
  border-top: 1px solid #ccdff4;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: 90px;
  margin-top: -5px;
  background-color: #fff;
  transform: rotateZ(45deg);
}
.dropdown__list__item {
  border-left: 1px solid #ccdff4;
  border-right: 1px solid #ccdff4;
  text-align: left;
  line-height: 36px;
  border-bottom: 1px solid #ccdff4;
  cursor: pointer;
  width: 100%;
}
.dropdown__list__item:first-child {
  border-top: 1px solid #ccdff4;
  border-radius: 3px 3px 0 0;
}
.dropdown__list__item:last-child {
  border-radius: 0 0 3px 3px;
}
.dropdown__list__item__text {
  line-height: 36px;
  color: #1d70b7;
  margin: 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown__list__item__link {
  display: block;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #1d70b7;
  margin: 0;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease 0.3s;
}
.dropdown__list__item__link:hover {
  background-color: #fff;
  color: #64a4da;
}
.dropdown__title:hover + .dropdown__list {
  display: block;
}
.header-molecule {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  height: 64px;
  width: 100%;
  backface-visibility: hidden;
  box-sizing: border-box;
}
.header-molecule-container {
  box-sizing: border-box;
  height: 75px;
  padding: 20px 7.03125%;
  width: 100%;
}
.header-molecule__logo {
  display: inline-block;
  float: left;
  cursor: pointer;
}
.header-molecule__logo__image__img {
  display: inline-block;
  height: 24px;
}
.header-molecule__logo__image__text {
  display: none;
  color: #4a4a4a;
}
.header-molecule__nav {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule {
  height: 64px;
  min-height: 64px;
  display: inline-block;
  vertical-align: top;
  margin-top: -20px;
  padding: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__mobile {
  display: none;
  position: absolute;
  margin: 0;
  left: 0;
  width: 100vw;
  top: 64px;
  border-radius: 0;
  border: 1px solid rgba(224,226,236,0.5);
  box-shadow: none;
  padding-left: 20px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box {
  height: 64px;
  box-shadow: none;
  border: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .-grow:after,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .-decrease:after {
  bottom: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
  height: 64px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__title,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__title,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__title {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__bottom,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__bottom,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__bottom {
  margin-top: -4px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .DateRangePickerInput {
  margin-top: 8px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .DateRangePickerInput__arrow {
  line-height: 50px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination {
  border-left: 1px solid rgba(224,226,236,0.5);
  border-right: 1px solid rgba(224,226,236,0.3);
  padding: 0 10px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest {
  width: 90%;
  display: inline-block;
  vertical-align: middle;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__input {
  height: 64px;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 64px;
  padding-top: 3px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
  margin-top: 0;
  width: calc(100vw - 136px);
  margin-left: -11px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 45px;
  height: 45px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item:first-child,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item:last-child {
  border-radius: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross {
  margin-top: 0;
  margin-left: 0;
  width: 10%;
  line-height: 55px;
  vertical-align: middle;
  display: inline-block;
  transition: all ease 0.5s;
  text-align: center;
  color: #c1c5d5;
  cursor: pointer;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross:before {
  line-height: 64px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross.-hidden {
  font-size: 0;
  transform: rotate(180deg);
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates {
  border-right: 1px solid rgba(224,226,236,0.3);
  width: 260px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput {
  width: 233px;
  margin-top: 5px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput__display-text--focused:after {
  bottom: -13px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput--with-caret:after,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput--with-caret:before {
  top: 69px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput__display-text {
  line-height: 45px;
  height: 50px;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePicker__picker {
  top: 80px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePicker__picker--direction-left {
  left: -220px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__title {
  height: 64px;
  line-height: 67px;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
  display: flex;
  align-items: center;
  border-right: none;
  padding: 0 10px;
  width: 107px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__bottom__text {
  font-size: 16px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__text,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons {
  padding-top: 0;
  line-height: 66px;
  height: 66px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons {
  line-height: 62px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons__button:last-child {
  margin-right: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action {
  padding: 0;
  border-right: 1px solid rgba(224,226,236,0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp {
  background-color: #353a8e;
  width: 70px;
  padding: 0 15px;
  min-width: 90px;
  justify-content: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button:before {
  color: #005fc9;
  font-size: 21px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp {
  display: flex;
  position: relative;
  align-items: center;
  width: auto;
  height: 40px;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-search,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading {
  display: block;
  position: absolute;
  top: 15px;
  left: 23px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp:before:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-search:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading:before {
  color: #005fc9;
  font-size: 20px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading:before {
  display: block;
  animation: infinite-spinning 1s linear infinite;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp__text {
  margin: 0 auto;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .-ie-button-fallback {
  margin: 0;
  text-align: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop.-is-hidden {
  display: none;
}
.header-molecule.-has-search-bar .header-molecule-container {
  padding: 20px;
}
.header-molecule.-has-search-bar .header-molecule__logo {
  margin-right: 20px;
}
.header-molecule.-hidden-first-header {
  height: 64px;
}
.header-molecule.-hidden-first-header .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__mobile {
  top: 0;
}
.header-molecule .search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
  margin-top: 0;
}
@media (min-width: 768px) {
  .header-molecule-container {
    padding: 20px;
  }
  .header-molecule__logo {
    vertical-align: middle;
  }
  .header-molecule__logo__image__img {
    vertical-align: middle;
    height: 20.25px;
  }
  .header-molecule__logo__image__img-text {
    display: inline-block;
    width: auto;
    height: 16px;
    line-height: 26px;
    margin-left: 8px;
    vertical-align: middle;
  }
  .header-molecule.-has-search-bar {
    border-bottom: 1px solid rgba(224,226,236,0.5);
    height: 65px;
  }
  .header-molecule.-has-search-bar .header-molecule-container {
    padding: 20px;
    height: 64px;
    display: inherit;
    justify-content: space-between;
    align-items: center;
  }
  .header-molecule.-has-search-bar .header-molecule__logo {
    margin-right: 20px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__mobile {
    display: none;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop {
    width: calc(100vw - 136px);
    display: block;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__destination {
    max-width: 340px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 351px;
    margin-left: -11px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .hundredicons-search:before,
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
    color: #005fc9;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .-is-serp-loading:before {
    color: #fff;
    left: 34px;
    top: 6px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button {
    padding-right: 15px;
    display: flex;
    justify-content: center;
    min-width: 131px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .hundredicons-search:before {
    display: none;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .hundredicons-icon-loading:before {
    color: #fff;
    left: 40%;
    top: 13%;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .-loading-serp {
    color: #353a8e;
  }
}
@media (max-width: 767px) {
  .header-molecule {
    display: none;
  }
}
@media (min-width: 1024px) {
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
    width: 662px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 662px;
    margin-left: -11px;
  }
  .header-molecule__nav-container {
    height: 100%;
  }
  .header-molecule__nav {
    display: inline-block;
    float: right;
    vertical-align: middle;
    color: #4a4a4a;
    background: none;
    width: auto;
    height: 100%;
    box-shadow: inherit;
  }
  .header-molecule__nav__item,
  .header-molecule__nav__item__seo-links,
  .header-molecule__nav__item__personal-area {
    display: inline-block;
    text-align: right;
    line-height: 26px;
    cursor: pointer;
    height: 100%;
    padding: 5px 15px;
  }
  .header-molecule__nav__item__link,
  .header-molecule__nav__item__seo-links__link,
  .header-molecule__nav__item__personal-area__link {
    color: #4a4a4a;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .header-molecule__nav__item__link:hover,
  .header-molecule__nav__item__seo-links__link:hover,
  .header-molecule__nav__item__personal-area__link:hover {
    color: #4a4a4a;
    opacity: 0.9;
    background: none;
  }
  .header-molecule__nav__item__img {
    height: 10px;
    margin-left: 10px;
    display: inline-block;
  }
  .header-molecule__nav__item__personal-area.dropdown {
    position: relative;
  }
  .header-molecule__nav__item__personal-area .dropdown__list {
    width: auto;
    right: 0;
    transform: none;
    overflow: initial;
  }
  .header-molecule__nav__item__personal-area .dropdown__list:before {
    margin-left: 0;
    right: 33%;
  }
  .header-molecule__nav__item__personal-area .dropdown__list__item__link {
    padding: 5px 15px;
  }
  .header-molecule__nav__item__personal-area .dropdown__list .personal-area__header__tabs__tab__text {
    min-width: 130px;
    padding: 0 17px;
    font-weight: 500;
  }
  .header-molecule__nav .hundredicons-iconcama,
  .header-molecule__nav .hundredicons-icon-plane {
    display: flex;
    align-items: baseline;
    flex-direction: row-reverse;
  }
  .header-molecule__nav .hundredicons-iconcama:before,
  .header-molecule__nav .hundredicons-icon-plane:before {
    margin-left: 5px;
    color: #4a4a4a;
  }
}
@media (min-width: 1250px) {
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
    width: 910px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination {
    padding: 0 5px 0 22px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 910px;
    margin-left: -23px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__date {
    padding: 0 2px 0 22px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
    width: 130px;
    padding: 0 15px 0 22px;
  }
}
@-moz-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.search-bar-molecule {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 0 7.03125%;
  margin: auto;
}
.search-bar-molecule__mobile {
  display: flex;
  justify-content: space-between;
  line-height: 65px;
  width: 100%;
  height: 64px;
  min-height: 64px;
  border-radius: 3px;
  color: #4a4a4a;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  border: 1px solid #ccdff4;
  max-width: 89%;
  letter-spacing: -0.5px;
  padding-left: 15px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
}
.search-bar-molecule__mobile__destination {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 63px;
  color: #4a4a4a;
  max-width: 80%;
  letter-spacing: -0.5px;
}
.search-bar-molecule__mobile__container {
  display: flex;
  height: 100%;
  width: 20%;
  background-color: #005fc9;
  float: right;
  border-radius: 0 3px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar-molecule__mobile__container__icon {
  height: 56px;
  font-size: 21px;
  color: #fff;
}
.search-bar-molecule__desktop {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}
.search-bar-molecule__desktop__container {
  width: 100%;
}
.search-bar-molecule__desktop__box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.15);
  border: 1px solid #ccdff4;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__destination .geosuggest__input {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__destination .geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__dates .DateInput__display-text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__guests__bottom__text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box__destination {
  width: 100%;
  height: 70px;
  padding: 14px 21px 12px;
  border-bottom: 1px solid #ccdff4;
  text-align: left;
  flex-shrink: 1;
}
.search-bar-molecule__desktop__box__destination__title {
  color: #005fc9;
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.search-bar-molecule__desktop__box__destination__input {
  position: relative;
  width: 100%;
}
.search-bar-molecule__desktop__box__destination__input .search-form-destination__remove-button {
  position: relative;
  margin-top: -20px;
  margin-left: 90%;
  color: rgba(74,74,74,0.5);
  font-size: 15px;
  letter-spacing: -0.25px;
  height: $heightInputs;
  width: 23px;
  line-height: $heightInputs;
  text-align: center;
  cursor: pointer;
  transition: all cubic-bezier(0.78, 0.6, 0.4, 0.99) 0.5s;
}
.search-bar-molecule__desktop__box__destination__input .search-form-destination__remove-button.-hidden {
  transform: rotate(180deg);
  visibility: hidden;
  opacity: 0;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest {
  padding: 0;
  width: 100%;
  margin: 0;
  display: inline-block;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input {
  height: 26px;
  line-height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: -0.5px;
  margin: 0;
  width: 100%;
  border: 0px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input:focus {
  outline: none;
  border: none;
  box-shadow: none;
  box-shadow: 0 0 0 transparent;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input {
  height: 100%;
  font-weight: 500;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input::-webkit-input-placeholder {
  color: rgba(0,0,0,0.4);
}
@media (min-width: 768px) {
  .search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input {
    height: auto;
  }
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .-formInvalid::-webkit-input-placeholder {
  color: #ff7165;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .-testA-placeHolder::-webkit-input-placeholder {
  color: #4a4a4a;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
  margin: 16px -22px;
  width: auto;
  margin-top: 14px;
  color: #4a4a4a;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
  border-radius: 3px;
  background: #fff;
  border: none;
  overflow: hidden;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item {
  padding: 0 22px;
  line-height: 64px;
  padding-left: 22px;
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 64px;
  line-height: 64px;
  display: flex;
  border-left: 1px solid #ccdff4;
  border-right: 1px solid #ccdff4;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item:first-child {
  border-top: 1px solid #ccdff4;
  border-radius: 3px 3px 0 0;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item:last-child {
  border-bottom: 1px solid #ccdff4;
  border-radius: 0 0 3px 3px;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-destination {
  width: 45%;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-arrival,
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-leaving {
  padding-right: 10px;
  width: 140px;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-guests {
  padding-right: 10px;
  width: 130px;
}
.search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
  width: auto;
  margin-top: 40px;
}
.search-bar-molecule__desktop__box__dates {
  border-bottom: 1px solid #ccdff4;
  padding: 14px 21px 12px;
  height: 70px;
}
.search-bar-molecule__desktop__box__dates__title {
  color: #005fc9;
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker {
  padding-left: 0;
  height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  margin-left: -3px;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput__arrow {
  padding: 0;
}
.search-bar-molecule__desktop__box__dates .DateInput {
  padding: 0;
}
.search-bar-molecule__desktop__box__dates .DateInput__input {
  cursor: pointer;
  height: 21px;
}
.search-bar-molecule__desktop__box__dates .DateInput__input__display-text {
  line-height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 21px;
  padding: 3px 0;
}
.search-bar-molecule__desktop__box__dates .DateInput .-formInvalid::-webkit-input-placeholder {
  color: #ff7165;
}
.search-bar-molecule__desktop__box__dates .-testA-placeHolder {
  color: #4a4a4a;
}
.search-bar-molecule__desktop__box__dates__info {
  position: absolute;
  bottom: 80px;
  margin-left: 0;
  display: flex;
  align-items: center;
}
.search-bar-molecule__desktop__box__dates__info.-without-checkboxes {
  bottom: 90px;
}
.search-bar-molecule__desktop__box__dates__info__dot {
  position: absolute;
  width: 5px;
  background-color: #17d280;
  height: 5px;
  border-radius: 50%;
  margin-left: 30px;
  margin-top: 1px;
}
.search-bar-molecule__desktop__box__dates__info__info {
  margin-left: 40px;
  font-size: 11px;
  letter-spacing: 0px;
}
.search-bar-molecule__desktop__box__dates__info__button-container {
  display: none;
}
.search-bar-molecule__desktop__box__guests {
  width: 100%;
  padding: 14px 21px 12px;
  user-select: none;
  text-align: left;
  flex-shrink: 0;
}
.search-bar-molecule__desktop__box__guests__title {
  display: none;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__guests__bottom {
  display: flex;
  justify-content: space-between;
  align-items: left;
}
.search-bar-molecule__desktop__box__guests__bottom__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4a4a4a;
  display: inline-block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
  text-transform: lowercase;
  vertical-align: middle;
  width: auto;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button {
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ccdff4;
  color: #005fc9;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  letter-spacing: -0.29px;
  height: 40px;
  justify-content: center;
  margin: 0;
  text-align: center;
  width: 40px;
  pointer-events: inherit;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:before {
  font-weight: 700;
  line-height: 0px;
  margin-right: 0px;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:first-child {
  margin-left: 0;
  margin-right: 10px;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:before {
  line-height: 0px;
}
.search-bar-molecule__desktop__box__action {
  width: auto;
  padding: 10px;
  text-align: right;
  flex-shrink: 0;
}
.search-bar-molecule__desktop__box__action__button-serp,
.search-bar-molecule__desktop__box__action__button {
  width: 100%;
  height: 60px;
  background-color: #353a8e;
  border-radius: 3px;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.search-bar-molecule__desktop__box__action .hundredicons-search:before,
.search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
  color: #fff;
  margin-right: 10px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  vertical-align: middle;
}
.search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
  display: inline-block;
  animation: infinite-spinning 1s linear infinite;
}
.search-bar-molecule__desktop__box .-serp-search-button {
  padding-right: 15px;
  display: flex;
  justify-content: center;
}
.search-bar-molecule__desktop__box .-serp-search-button .-loading-serp {
  color: #353a8e;
}
.search-bar-molecule__desktop__box .-serp-search-button .hundredicons-icon-loading:before {
  position: absolute;
  color: #fff;
  left: 40%;
  top: 29%;
}
.search-bar-molecule__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 980px;
  margin: auto;
}
.search-bar-molecule__bottom__checkboxes {
  display: flex;
  flex-direction: flex-start;
  width: 100%;
  margin: 0 auto;
  max-width: 980px;
  height: 75px;
  line-height: 75px;
  padding-left: 25px;
  align-items: center;
}
.search-bar-molecule__bottom__checkboxes.-is-on-calendar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.search-bar-molecule__bottom__checkboxes__item {
  width: auto;
}
.search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
  color: #9e9e9e;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 50px;
}
.search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text.-is-on-calendar {
  font-size: 12px;
  letter-spacing: -0.29px;
}
.search-bar-molecule__bottom__checkboxes__title {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 19px;
  margin: 0 10px 0 0;
  line-height: 20px;
  text-align: left;
}
.search-bar-molecule__bottom__checkboxes__title.-is-on-calendar {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-left: 5px;
}
.search-bar-molecule__bottom__checkboxes__checkbox {
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: normal;
  line-height: 50px;
  color: #4a4a4a;
}
.search-bar-molecule__bottom__compare-image {
  align-items: center;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  width: 100%;
}
.home.sem .search-bar-molecule__bottom__compare-image {
  display: flex;
  align-items: baseline;
}
.search-bar-molecule__bottom__compare-image__img {
  background-image: url("/img/pages/sem/compare-free-arrow.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  text-indent: -1000%;
  overflow: hidden;
  height: 24px;
  width: 30px;
}
@media (min-width: 768px) {
  .search-bar-molecule__bottom__compare-image__img {
    background-image: url("/img/pages/sem/compare-free-arrow.png");
  }
}
.search-bar-molecule__bottom__compare-image__text {
  display: table;
  white-space: pre-wrap;
  margin-right: 0.1em;
  width: 5em;
  line-height: 1.1em;
  font-size: 0.9em;
  transform-origin: center right;
  transform: rotate(-15deg) translateY(-10px);
  text-align: center;
  color: #fff;
  font-weight: 500;
}
@media (min-width: 768px) {
  .search-bar-molecule__bottom__compare-image__text {
    color: #005fc9;
    font-size: 1.1em;
  }
}
.search-bar-molecule.-show-color-box {
  background-color: #005fc9;
  border-radius: 8px;
  padding: 18px 22px 0;
  width: calc(100% - 32px);
  max-width: 600px;
  transform: translateZ(0);
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile {
  padding: 4px 4px 4px 15px;
  max-width: 100%;
  border: 0;
  box-shadow: none;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__destination {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #61625e;
  line-height: 56px;
  font-weight: normal;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__destination.-has-not-destination:before {
  animation-name: opacity;
  animation-duration: 0.8s;
  animation-delay: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  display: inline-block;
  content: ' ';
  height: 22px;
  background-color: #005fc9;
  width: 2px;
  transform: translateY(3px);
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__container {
  background-color: #1c1f56;
  border-radius: 3px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__container__icon {
  color: #fff;
  height: 56px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes {
  min-height: 38px;
  padding: 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes.-is-empty {
  height: 0;
  min-height: 0;
  padding-top: 5px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__title,
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__checkbox {
  color: #fff;
  font-size: 13px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__text {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__item .hundred-ui-checkbox__label__text {
  color: #fff;
  font-size: 13px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__item .hundred-ui-checkbox__label:after {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.search-bar-molecule .-grow {
  position: relative;
}
.search-bar-molecule .-grow:after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background-color: #1c1f56;
  position: absolute;
  bottom: -1px;
  max-width: 63%;
  animation: grow 0.3s linear;
  animation-fill-mode: forwards;
}
@media (min-width: 1024px) {
  .search-bar-molecule__mobile {
    display: none;
  }
}
@media (min-width: 768px) {
  .search-bar-molecule {
    height: 20vh;
    min-height: 100px;
  }
  .search-bar-molecule .search-form-destination__remove-button {
    display: inline-block;
    margin-left: 0;
    margin-top: 0;
  }
  .search-bar-molecule.-show-color-box {
    background-color: #fff;
    padding: 30px 30px 0;
    height: auto;
    min-height: inherit;
    max-width: 1040px;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__desktop__box__action__button {
    background-color: #353a8e;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes {
    min-height: 30px;
    height: auto;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__title {
    color: #4a4a4a;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes.-is-empty {
    height: inherit;
    min-height: 62px;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label:after {
    box-shadow: inset 0 0 0 1px #ccdff4;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
    color: #9e9e9e;
  }
  .search-bar-molecule__bottom__checkboxes.is-on-calendar {
    width: 50%;
  }
  .search-bar-molecule__desktop {
    display: block;
  }
  .search-bar-molecule__desktop__box {
    flex-direction: row;
    height: 82px;
  }
  .search-bar-molecule__desktop__box__destination {
    max-width: 400px;
    border-right: 1px solid #ccdff4;
    height: 82px;
    border-bottom: 0;
    padding: 0 5px 0 22px;
  }
  .search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
    width: 86vw;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest {
    width: 88%;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest__input {
    height: 44px;
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 44px;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    width: 86vw;
    margin-left: -23px;
    max-width: 980px;
  }
  .search-bar-molecule__desktop__box__destination__suggestion-list {
    position: relative;
  }
  .search-bar-molecule__desktop__box__destination__suggestion-list__title {
    position: absolute;
    top: 15px;
    color: #1c1f56;
  }
  .search-bar-molecule__desktop__box .-grow:after {
    bottom: 1px;
    transform: translateX(-22px);
    max-width: inherit;
  }
  .search-bar-molecule__desktop__box__dates {
    border-right: 1px solid #ccdff4;
    border-bottom: 0;
    padding: 0;
    height: 82px;
    padding-left: 22px;
    max-width: 33%;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container {
    height: 75px;
    line-height: 75px;
    position: absolute;
    right: 0;
    margin-right: 25px;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__nights {
    padding-right: 20px;
    color: #9e9e9e;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button {
    height: 40px;
    width: 125px;
    border-radius: 3px;
    background-color: #353a8e;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: -0.25px;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:before {
    padding-right: 10px;
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:hover {
    cursor: pointer;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled {
    cursor: default;
    color: #fff;
    opacity: 0.5;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:before {
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:hover,
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:focus {
    border-color: #353a8e;
    background-color: #353a8e;
    cursor: default;
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker {
    margin-top: 0.1em;
    padding-left: 0;
    height: inherit;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-no-margin {
    height: 310px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin {
    height: 360px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin:before {
    bottom: 45px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-with-cheapest {
    height: 360px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-with-cheapest:before {
    bottom: 45px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-without-checkboxes {
    bottom: 20px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput {
    width: 272px;
    overflow: visible;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput {
    padding: 0;
    height: 44px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput__input {
    cursor: pointer;
    height: 44px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput__display-text {
    line-height: 35px;
    height: 35px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput__arrow {
    height: 44px;
    line-height: 44px;
  }
  .search-bar-molecule__desktop__box__guests {
    padding: 0 18px 0 18px;
    height: 82px;
    width: auto;
    min-width: 130px;
  }
  .search-bar-molecule__desktop__box__guests__bottom {
    margin-top: 7px;
    padding-top: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  .search-bar-molecule__desktop__box__guests__bottom__text {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 25px;
    min-width: 31px;
    margin-right: 5px;
    text-align: left;
  }
  .search-bar-molecule__desktop__box__guests__bottom__text__description {
    display: none;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons {
    padding-top: 5px;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons__button {
    height: 26px;
    width: 26px;
    font-size: 10px;
    letter-spacing: 0px;
    margin: 0 3px;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons__button:first-child {
    margin-right: 3px;
  }
  .search-bar-molecule__desktop__box__action {
    min-width: 150px;
  }
  .search-bar-molecule__desktop__box__action__button {
    background-color: #005fc9;
  }
  .search-bar-molecule__desktop__box__action__button:before {
    color: #fff;
  }
  .search-bar-molecule__desktop__box__destination__title,
  .search-bar-molecule__desktop__box__dates__title,
  .search-bar-molecule__desktop__box__guests__title {
    display: block;
    height: auto;
    width: auto;
    margin-top: calc(30px - 1.2em);
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: initial;
    font-size: 15px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    line-height: 1.2em;
    text-transform: inherit;
  }
  .search-bar-molecule__bottom {
    flex-direction: row;
  }
  .search-bar-molecule__bottom__checkboxes__title {
    line-height: 50px;
  }
  .search-bar-molecule__bottom__compare-image {
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 40px;
  }
  .search-bar-molecule__bottom__compare-image__img {
    background-image: url("/img/pages/sem/compare-free-arrow-desktop.png");
  }
}
.search-bar-molecule.-sem-outline-test .search-bar-molecule__desktop {
  border-radius: 5px;
  border: 8px solid #005fc9;
}
.search-bar-molecule.-sem-outline-test .search-bar-molecule__desktop__box {
  border: none;
}
@media (min-width: 1201px) {
  .search-bar-molecule__desktop__box__date {
    width: 280px;
  }
}
@-moz-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-o-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-moz-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-webkit-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-o-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-moz-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1201px) {
  .search-bar-molecule__desktop__box__destination__input {
    display: inline-flex;
  }
}
.home {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  color: #4a4a4a;
  width: 100%;
  box-sizing: border-box;
  display: block;
}
.home__body {
  width: 100%;
  padding-top: 50px;
}
.home__body-container {
  width: 100%;
}
.home__body__partners {
  max-width: 100vw;
  overflow: hidden;
}
.home__body__partners__scroll {
  display: none;
  animation: moveSlideshow 750s linear infinite;
  background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
  background-repeat: repeat-x;
  background-position: center 40px;
  background-size: 100vw;
  height: 150px;
  width: 10000vw;
}
.home__body__partners-container {
  display: none;
}
.home__body__steps-mobile {
  padding: 30px 7.03125%;
}
.home__body__steps {
  padding: 30px 7.03125%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
}
.home__body__destinations {
  padding: 0;
}
.home__body__destinations__title__line {
  display: none;
}
.home__body__destinations__title__text {
  width: 100%;
  font-size: 21px;
  line-height: 26px;
  text-align: left;
  padding: 0 7.03125% 38px;
  letter-spacing: -0.25px;
}
.home__body__destinations__list {
  width: 100vw;
  overflow: hidden;
}
.home__body__destinations__list__container {
  padding-left: 7.03125%;
  width: 920px;
}
.home__body__destinations__list__innocuos-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.home__body__destinations__list__innocuos-container::-webkit-scrollbar {
  display: none;
}
.home__body__destinations__list__item {
  display: inline-block;
  height: 300px;
  width: 200px;
  opacity: 1;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: top;
}
.home__body__destinations__list__item:first-child {
  padding-left: 0;
}
.home__body__destinations__list__item__image {
  background-position: 50% 50%;
  background-repeat: none;
  background-size: auto 100%;
  display: block;
  width: 100%;
  height: 100%;
}
.home__body__destinations__list__item__wrapper {
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -ms-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -o-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 10px;
}
.home__body__destinations__list__item__wrapper__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.home__body__destinations__list__item__wrapper__text-wrapper__name {
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 22px;
  text-align: center;
  margin: 0;
}
.home__body__destinations__list__item__wrapper__text-wrapper__number {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
  letter-spacing: -0.25px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}
.home__body__destinations-mini {
  width: 100%;
  padding: 30px 7.03125%;
}
.home__body__destinations-mini__header__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 26px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: -0.25px;
}
.home__body__destinations-mini__header__link {
  display: none;
}
.home__body__destinations-mini__list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home__body__destinations-mini__list__item {
  height: 100px;
  border-top: 1px solid #ccdff4;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.home__body__destinations-mini__list__item:last-child {
  border-bottom: 1px solid #ccdff4;
}
.home__body__destinations-mini__list__item-wrapper {
  display: flex;
  padding: 10px 0;
}
.home__body__destinations-mini__list__item__image {
  width: 100px;
  height: 80px;
  flex-shrink: 0;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all ease 0.3s;
}
.home__body__destinations-mini__list__item__text {
  flex-shrink: 1;
  width: 100%;
  padding-left: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home__body__destinations-mini__list__item__text__name {
  display: block;
  width: 90%;
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin: 0;
  text-align: left;
  font-weight: 700;
}
.home__body__destinations-mini__list__item__text__number {
  display: block;
  width: 90%;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
  color: #9b9b9b;
  margin: 0;
  text-transform: uppercase;
}
.home__body__destinations-mini__footer {
  text-align: right;
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.home__body__destinations-mini__footer__link {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 23px;
  color: #005fc9;
  text-transform: uppercase;
  vertical-align: middle;
}
.home__body__destinations-mini__footer__link__icon {
  font-size: 10px;
  letter-spacing: 0px;
  padding-left: 5px;
  line-height: 11px;
}
.home__body__compare {
  width: 100%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
  padding: 45px 0;
}
.home__body__compare__text {
  padding: 0 7.03125%;
}
.home__body__compare__text__title {
  font-size: 21px;
  letter-spacing: -0.25px;
  line-height: 26px;
  padding-bottom: 30px;
  text-align: left;
  font-weight: 700;
}
.home__body__compare__text__paragraf {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  font-weight: 400;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}
.home__body__compare__image {
  width: 80%;
  max-width: 400px;
  text-align: center;
  margin: 20px auto;
}
.home__body__compare__image__img {
  background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
  width: 80%;
  margin: auto;
  padding: 50%;
  background-position: 50% 100%;
  background-size: 100% auto;
  display: inline-block;
  background-repeat: no-repeat;
}
.home__body__app {
  width: 100%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
  padding: 45px 0 0;
  display: flex;
  flex-direction: column;
}
.home__body__app__image {
  text-align: center;
  order: 2;
  display: none;
}
.home__body__app__image__img {
  width: 70%;
  max-width: 300px;
}
.home__body__app__text {
  order: 1;
  padding: 0 7.03125%;
}
.home__body__app__text__title {
  font-size: 21px;
  line-height: 26px;
  padding-bottom: 30px;
  letter-spacing: -0.25px;
  text-align: left;
  font-weight: 700;
}
.home__body__app__text__paragraf {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  font-weight: 400;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}
.home__body__app__text__appstores {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.home__body__app__text__appstores__apple,
.home__body__app__text__appstores__android {
  padding: 0 20px;
}
.home__body__app__text__appstores__apple__img,
.home__body__app__text__appstores__android__img {
  width: 100%;
  max-width: 100px;
}
@media (min-width: 600px) {
  .home__body__partners__scroll {
    background-size: 70vw;
  }
}
@media (min-width: 768px) {
  .home__header .header-molecule-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home__header .header-molecule__logo {
    min-width: 200px;
  }
  .home__header .header-molecule__nav__item {
    display: inline-flex;
    align-items: center;
  }
  .home__body {
    width: 100%;
    padding-top: 0px;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .transition-container--horizontal {
    height: 310px !important;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin,
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-above-margin {
    height: 360px !important;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin:before,
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-above-margin:before {
    bottom: 45px;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-without-checkboxes {
    bottom: 20px;
  }
  .home__body-container {
    max-width: 1200px;
    margin: auto;
  }
  .home__body__claim-desktop {
    height: 30vh;
    min-height: 200px;
  }
  .home__body__claim-desktop .claim__title,
  .home__body__claim-desktop .claim__subtitle {
    font-weight: 700;
  }
  .home__body__partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    min-height: 60px;
  }
  .home__body__partners__scroll {
    display: none;
  }
  .home__body__partners-container {
    display: flex;
    width: 100%;
  }
  .home__body__partners__line {
    flex-shrink: 1;
    width: 50%;
    height: 27.5px;
    border-bottom: 1px solid #ccdff4;
    vertical-align: middle;
    border-radius: 2px;
    display: block;
  }
  .home__body__partners__image {
    display: inline-block;
    width: 550px;
    flex-shrink: 0;
    margin: 0 10px;
    height: 55px;
    background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }
  .home__body__steps {
    border: none;
    padding-top: 0;
  }
  .home__body__destinations {
    max-width: 1200px;
    margin: auto;
    padding: 50px 40px;
  }
  .home__body__destinations__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
  }
  .home__body__destinations__title__line {
    flex-shrink: 1;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ccdff4;
    vertical-align: middle;
    border-radius: 2px;
    display: block;
  }
  .home__body__destinations__title__text {
    flex-shrink: 0;
    max-width: 480px;
    text-align: center;
    padding: 0 30px;
    width: auto;
    line-height: 34px;
    letter-spacing: -0.25px;
    font-size: 28px;
    height: inherit;
  }
  .home__body__destinations__list {
    width: 100%;
    overflow: visible;
    padding-bottom: 40px;
  }
  .home__body__destinations__list__container {
    padding: 0;
    margin: auto;
    width: 100%;
    display: flex;
    overflow: visible;
  }
  .home__body__destinations__list__innocuos-container {
    overflow: visible;
  }
  .home__body__destinations__list__item {
    width: 100vw;
    height: 400px;
    margin-right: 20px;
    border-radius: 3px;
    transition: all ease 0.3s;
    overflow: hidden;
  }
  .home__body__destinations__list__item:last-child {
    margin-right: 0;
  }
  .home__body__destinations__list__item:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.3);
    transform: translateY(-10px);
  }
  .home__body__destinations__list__item-image {
    transition: all ease 0.3s;
  }
  .home__body__destinations__list__item-image:hover {
    transform: scale(1.05);
  }
  .home__body__destinations__list__item__wrapper {
    border-radius: 3px;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper {
    height: 80vw;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper__name {
    font-size: 24px;
    width: auto;
    line-height: 30px;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper__number {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home__body__destinations-mini {
    max-width: 1200px;
    margin: auto;
    padding: 30px 40px;
  }
  .home__body__destinations-mini__header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  .home__body__destinations-mini__header__title {
    display: block;
  }
  .home__body__destinations-mini__header__link {
    display: block;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 23px;
    color: #005fc9;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .home__body__destinations-mini__header__link__icon {
    font-size: 10px;
    letter-spacing: 0px;
    padding-left: 5px;
    line-height: 11px;
  }
  .home__body__destinations-mini__list {
    padding: 0 0;
  }
  .home__body__destinations-mini__list-container {
    width: 100%;
    flex-direction: row;
  }
  .home__body__destinations-mini__list__item {
    margin-right: 20px;
    border-bottom: 1px solid #ccdff4;
    display: inline-block;
    width: 25%;
    flex-shrink: 1;
    transition: all ease 0.3s;
  }
  .home__body__destinations-mini__list__item:last-child {
    margin-right: 0;
  }
  .home__body__destinations-mini__list__item:hover {
    opacity: 0.8;
  }
  .home__body__destinations-mini__list__item__text__number {
    max-width: 85%;
    text-align: left;
  }
  .home__body__destinations-mini__footer {
    display: none;
  }
  .home__body__compare {
    border: none;
    margin: auto;
    text-align: center;
    padding: 7.03125% 7.03125%;
    max-width: 1200px;
    width: 80%;
  }
  .home__body__compare__text {
    width: 50%;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
  }
  .home__body__compare__text__paragraf {
    width: 100%;
    max-width: 470px;
  }
  .home__body__compare__text__paragraf:first-child {
    padding-bottom: 20px;
  }
  .home__body__compare__image {
    display: inline-block;
    width: 50%;
    padding-top: 0;
    vertical-align: middle;
    padding-left: 7.03125%;
  }
  .home__body__compare__image__img {
    max-width: 365px;
    width: 100%;
  }
  .home__body__app {
    flex-direction: row;
    justify-content: center;
    border: none;
    padding: 50px 7.03125% 0px;
  }
  .home__body__app__image {
    order: 1;
    align-self: flex-end;
    padding: 0 7.03125% 0 0;
    display: block;
  }
  .home__body__app__image__img {
    display: block;
    background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
    height: 450px;
    padding: 50%;
    width: 300px;
    background-size: 300px;
    background-position: 50% 24%;
    background-size: 550px;
  }
  .home__body__app__text {
    order: 2;
    max-width: 450px;
    flex-shrink: 1;
    padding: 0;
    align-self: flex-end;
  }
  .home__body__app__text__appstores {
    justify-content: flex-start;
  }
  .home__body__app__text__appstores__apple {
    padding-left: 0;
  }
  .home .home-version-new {
    padding-top: 90px;
    margin-top: -230px;
    padding-top: 280px;
    min-height: 505px;
  }
  .home .home-version-new .claim {
    align-items: flex-start;
    padding: 0 30px;
    margin: 0 auto;
    max-width: 1040px;
    width: calc(100% - 32px);
    height: auto;
    min-height: auto;
  }
  .home .home-version-new .claim__title {
    margin: 0;
    text-align: left;
    font-size: 36px;
    color: #4a4a4a;
    font-weight: 300;
    -webkit-text-fill-color: inherit;
    max-width: 100%;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .claim__subtitle {
    color: #2e3085;
    font-size: 32px;
    margin: 0;
  }
  .home .home-version-new .search-bar-molecule {
    padding-top: 50px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box {
    border: 1px solid #669fdf;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__destination,
  .home .home-version-new .search-bar-molecule__desktop__box__dates {
    border-right: 1px solid #669fdf;
    height: 81px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__destination__title,
  .home .home-version-new .search-bar-molecule__desktop__box__dates__title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__guests__title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .home__body__partners,
  .home .home-version-new .home__body__steps-desktop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1120px) {
  .home__body__destinations__list__item {
    height: 34vw;
  }
  .home__body__destinations__list__item__name {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 22px;
    transition: all ease 0.3s;
  }
  .home__body__destinations__list__item__number {
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 19px;
    transition: all ease 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .home__body__destinations-mini__list {
    padding: 0 0;
  }
  .home__body__destinations-mini__list-container {
    width: 100%;
    display: inline-block;
  }
  .home__body__destinations-mini__list__item {
    margin-right: 5%;
    display: inline-block;
    width: 47.5%;
  }
  .home__body__destinations-mini__list__item:nth-child(4),
  .home__body__destinations-mini__list__item:nth-child(2) {
    margin-right: 0;
  }
  .home__body__destinations-mini__list__item:nth-child(3),
  .home__body__destinations-mini__list__item:nth-child(4) {
    border-top: 0;
  }
  .home__body__destinations-mini__list__item:hover .home__body__destinations-mini__list__item__image {
    transition: all ease 0.3s;
    opacity: 0.8;
  }
  .home__body__destinations-mini__footer {
    display: none;
  }
}
@-moz-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@-webkit-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@-o-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
.location-list__list {
  margin: 30px 40px;
  display: flex;
  flex-wrap: wrap;
}
.location-list__list__item {
  width: 47.5%;
  color: #b3b3b3;
  padding-bottom: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.location-list__list__item:nth-child(2n+1) {
  margin-right: 5%;
}
.location-list__list__item:hover {
  color: #005fc9;
}
@media (min-width: 1220px) {
  .location-list__list {
    margin: 40px;
    margin-bottom: 10px;
  }
  .location-list__list__item {
    padding-bottom: 12px;
    width: 25%;
    margin-right: 20px;
    max-width: 265px;
  }
  .location-list__list__item:nth-child(2n+1) {
    margin-right: 20px;
  }
  .location-list__list__item:nth-child(4n) {
    margin-right: 0;
  }
}
.location-list.-no-top {
  margin-top: 40px;
  margin-bottom: -30px;
}
@media (min-width: 768px) {
  .location-list.-no-top {
    margin-top: -40px;
    margin-bottom: inherit;
  }
}
.location-list.-first-destinations {
  margin: -10px 0 20px;
}
@media (min-width: 768px) {
  .location-list.-first-destinations {
    margin: inherit;
  }
}
.location-list.-big-destinations {
  margin-top: -10px;
}
@media (min-width: 768px) {
  .location-list.-big-destinations {
    margin-top: inherit;
  }
}
.footer-molecule {
  background-color: #fff;
  border-top: 1px solid #ccdff4;
  box-sizing: border-box;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.footer-molecule #publishStablishment {
  width: 34%;
}
.footer-molecule__first-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 45px 7.03125% 50px;
  max-width: 1280px;
  margin: auto;
}
.footer-molecule__first-footer__links {
  width: 100%;
  margin: auto;
}
.footer-molecule__first-footer__links__about-us,
.footer-molecule__first-footer__links__establishment-type,
.footer-molecule__first-footer__links__publish-establishment,
.footer-molecule__first-footer__links__international {
  text-align: left;
  padding: 0 0 30px 0;
}
.footer-molecule__first-footer__links__about-us__title,
.footer-molecule__first-footer__links__establishment-type__title,
.footer-molecule__first-footer__links__publish-establishment__title,
.footer-molecule__first-footer__links__international__title {
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin: 0;
  cursor: default;
  padding-bottom: 10px;
  letter-spacing: -0.5px;
  font-weight: 700;
  text-align: left;
}
.footer-molecule__first-footer__links__about-us__subtext,
.footer-molecule__first-footer__links__establishment-type__subtext,
.footer-molecule__first-footer__links__publish-establishment__subtext,
.footer-molecule__first-footer__links__international__subtext {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 20px;
  color: rgba(0,0,0,0.4);
  margin: 0 0 20px;
  text-align: left;
}
.footer-molecule__first-footer__links__about-us__link,
.footer-molecule__first-footer__links__establishment-type__link,
.footer-molecule__first-footer__links__publish-establishment__link,
.footer-molecule__first-footer__links__international__link {
  letter-spacing: -0.5px;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 30px;
  color: #005fc9;
  text-transform: uppercase;
}
.footer-molecule__first-footer__newsletter {
  display: none;
  width: 100%;
  margin: auto;
  text-align: left;
}
.footer-molecule__first-footer__newsletter__title {
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin: 0 0 14px 0;
}
.footer-molecule__first-footer__newsletter__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  margin: 0 0 10px;
  text-align: left;
}
.footer-molecule__first-footer__newsletter__input {
  width: 70%;
  display: inline-block;
  height: 47px;
  border: 1px solid #ccdff4;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding: 0 10px;
  margin-right: 5%;
}
.footer-molecule__first-footer__newsletter__input::placeholder {
  color: #005fc9;
}
.footer-molecule__first-footer__newsletter__input:focus {
  outline: none;
  border-color: #005fc9;
}
.footer-molecule__first-footer__newsletter__button {
  display: inline-block;
  width: 25%;
  min-width: inherit;
  height: 47px;
  border-radius: 3px;
  padding: 0 10px;
  text-align: center;
  color: #fff;
  background-color: #005fc9;
}
.footer-molecule__first-footer__newsletter__button:hover,
.footer-molecule__first-footer__newsletter__button:focus {
  background: rgba(0,95,201,0.9);
}
.footer-molecule__second-footer {
  align-items: initial;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1280px;
  margin: auto;
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 30px 7.03125%;
}
.footer-molecule__second-footer__copyright {
  text-align: center;
  order: 3;
  user-select: none;
  cursor: default;
}
.footer-molecule__second-footer__copyright__logo {
  display: none;
}
.footer-molecule__second-footer__copyright__text {
  font-size: 12px;
  letter-spacing: -0.29px;
  text-align: center;
  line-height: 19px;
  margin: 0;
}
.footer-molecule__second-footer__made {
  width: 100%;
  margin: 30px auto;
  order: 2;
  user-select: none;
  cursor: default;
}
.footer-molecule__second-footer__made__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 23px;
  color: #9b9b9b;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  width: 165px;
}
.footer-molecule__second-footer__made__text .hundredicons-palmtree {
  color: #00a600;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.footer-molecule__second-footer__made__text .hundredicons-heart-filled {
  color: #d0021b;
  font-size: 11px;
  letter-spacing: 0px;
  vertical-align: baseline;
}
.footer-molecule__second-footer__social-links {
  padding: 0 7.03125%;
  order: 1;
  user-select: none;
}
.footer-molecule__second-footer__social-links.-not-border-top {
  border-top: 0;
}
.footer-molecule__second-footer__social-links__list {
  display: flex;
  justify-content: center;
}
.footer-molecule__second-footer__social-links__list__item {
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.footer-molecule__second-footer__social-links__list__item:first-child {
  padding-left: 0;
}
.footer-molecule__second-footer__social-links__list__item:last-child {
  padding-right: 0;
}
.footer-molecule__second-footer__social-links__list__item__link {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 0.90000004px solid rgba(176,190,197,0.5);
  display: block;
  text-align: center;
  transition: all ease 0.3s;
  cursor: pointer;
  line-height: 40px;
  background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
  background-repeat: no-repeat;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-facebook {
  background-size: 100%;
  background-position: -2px 7px;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-twitter {
  background-position: 4px -16px;
  background-size: 80%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-linkedin {
  background-position: 3px -55px;
  background-size: 90%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-google {
  background-position: 3px -76px;
  background-size: 86%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-youtube {
  background-position: 3px -100px;
  background-size: 83%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-instagram {
  background-position: 2px -144px;
  background-size: 90%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-pinterest {
  background-position: 2px -180px;
  background-size: 90%;
}
@media (min-width: 1024px) {
  .footer-molecule__second-footer__copyright__logo {
    width: 42.96px;
    height: 30px;
    display: block;
    margin: 0 20px;
    background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
    background-position: 4px -247px;
    background-repeat: no-repeat;
    background-size: 80%;
  }
}
@media (min-width: 768px) {
  .footer-molecule {
    border-top: 1px solid #e6e6e6;
    padding-bottom: 40px;
  }
  .footer-molecule__first-footer {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .footer-molecule__first-footer__links {
    align-items: flex-start;
    display: flex;
    width: 100%;
    margin-top: 0;
  }
  .footer-molecule__first-footer__links__about-us,
  .footer-molecule__first-footer__links__establishment-type {
    width: 34%;
  }
  .footer-molecule__first-footer__links__about-us__title,
  .footer-molecule__first-footer__links__establishment-type__title {
    text-align: left;
    width: 95%;
    margin-bottom: 13px;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer-molecule__first-footer__newsletter {
    width: 25%;
    margin-top: 0;
  }
  .footer-molecule__first-footer__newsletter__input {
    width: 65%;
    margin-right: 3%;
  }
  .footer-molecule__first-footer__newsletter__button {
    width: 30%;
  }
  .footer-molecule__second-footer {
    flex-direction: row;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0;
  }
  .footer-molecule__second-footer__copyright {
    order: 1;
    width: 33%;
    display: flex;
    align-items: center;
  }
  .footer-molecule__second-footer__copyright__img {
    display: inline-block;
    width: 44px;
    height: 29px;
    flex-shrink: 0;
    margin: 0 20px 0 0;
  }
  .footer-molecule__second-footer__copyright__text {
    display: inline-block;
    max-width: 230px;
    text-align: left;
    font-weight: 300;
  }
  .footer-molecule__second-footer__copyright__text a {
    color: #005fc9;
  }
  .footer-molecule__second-footer__made {
    order: 2;
    width: 33%;
    margin: 0;
  }
  .footer-molecule__second-footer__made__text {
    width: 260px;
  }
  .footer-molecule__second-footer__social-links {
    order: 3;
    width: 34%;
    padding-right: 0;
  }
  .footer-molecule__second-footer__social-links__list {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .footer-molecule__second-footer__copyright__logo {
    width: 42.96px;
    height: 30px;
    display: block;
    margin: 0 auto 20px;
    background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
    background-position: 4px -247px;
    background-repeat: no-repeat;
    background-size: 80%;
  }
}
.language-currency {
  padding-left: 10px;
  user-select: none;
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  height: 47px;
  display: block;
  border: 1px solid #ccdff4;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.language-currency__image-container {
  height: 25px;
  width: 25px;
  overflow: hidden;
  position: relative;
}
.language-currency__selected,
.language-currency__list__item__link {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.language-currency__selected__img,
.language-currency__list__item__link__img {
  width: 30px;
  flex-grow: 0;
  margin-left: 15px;
  display: block;
  height: 23px;
  vertical-align: middle;
}
.language-currency__selected__text,
.language-currency__list__item__link__text {
  line-height: 45px;
  font-size: 14px;
  letter-spacing: -0.25px;
  appearance: none;
  color: #005fc9;
  margin: 0;
  flex-grow: 4;
  padding-left: 10px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.language-currency__selected__icon {
  flex-grow: 0;
  line-height: 45px;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-right: 15px;
  transition: all ease 0.3s;
  transform-origin: 50% 47%;
}
.language-currency__selected__icon:before {
  line-height: 45px;
}
.language-currency__list {
  border-radius: 3px;
  transition: transition 0.2s ease, opacity 0.3s ease;
  background-color: #fff;
  border: 1px solid #ccdff4;
  background-color: #fff;
  color: #000;
  opacity: 0;
  transform: scale(0) translateX(-400px) translateY(-400px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 693px;
  max-width: initial;
  height: 200px;
}
.language-currency__list:first-child {
  display: none;
}
.language-currency__list__item {
  padding-left: 10px;
  border: none;
  user-select: none;
  width: 100%;
  max-width: 229px;
  height: 47px;
  transition: all ease 0.3s;
  margin-left: 1px;
}
.language-currency__list__item:first-child {
  display: none;
}
.language-currency__list__item__link {
  width: 100%;
}
.language-currency__list__item__link__text {
  color: #4a4a4a;
}
.language-currency__list__item__link__text:hover {
  color: #005fc9;
}
.language-currency .-arrow {
  transform: rotateZ(-180deg);
}
.language-currency .-showing {
  display: flex;
  opacity: 1;
  transform: scale(1) translateX(-464px) translateY(-252px);
}
@media (max-width: 767px) {
  .language-currency .-showing {
    transform: scale(1) translateX(-10px) translateY(-254px);
  }
}
@media (max-width: 500px) {
  .language-currency .-showing {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
}
.language-currency .-not-showing {
  opacity: 0;
  transform: scale(0);
}
@media (max-width: 500px) {
  .language-currency .-not-showing {
    display: none;
    opacity: 0;
    transform: scale(0);
  }
}
@media (max-width: 500px) {
  .language-currency__list {
    overflow-y: scroll;
    position: fixed;
    display: block;
    display: none;
    width: 100vw;
    height: 100vh;
    max-width: none;
    left: 0px;
    right: 0;
    top: 0;
    z-index: 3;
  }
  .language-currency__list__item {
    padding-left: 50px;
    max-width: inherit;
    margin-bottom: 10px;
  }
  .language-currency__list__item:first-child {
    display: inline-block;
    height: 35px;
  }
  .language-currency__list__item__link__text {
    font-size: 18px;
    letter-spacing: -0.25px;
  }
  .language-currency__list__item__icon {
    color: #979797;
    line-height: 14px;
    display: block;
    text-align: center;
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
